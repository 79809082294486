import React, { Component } from "react";

export default class App extends Component {
    static displayName = App.name;

    constructor(props) {
        super(props);
        this.state = { forecasts: [], todo_items: [], loading_forecasts: true, loading_todo_items: true };
    }

    componentDidMount() {
        this.populateWeatherData();
        this.populateTodoData();
    }

    static renderForecastsTable(forecasts) {
        return (
            <table className='table table-striped' aria-labelledby="forecastTabelLabel">
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Temp. (C)</th>
                        <th>Temp. (F)</th>
                        <th>Summary</th>
                    </tr>
                </thead>
                <tbody>
                    {forecasts.map(forecast =>
                        <tr key={forecast.date}>
                            <td>{forecast.date}</td>
                            <td>{forecast.temperatureC}</td>
                            <td>{forecast.temperatureF}</td>
                            <td>{forecast.summary}</td>
                        </tr>
                    )}
                </tbody>
            </table>
        );
    }

    static renderTodoItemsTable( todo_items )
    {
        return (
            <table className='table table-striped' aria-labelledby="todoItemsTabelLabel">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Name</th>
                        <th>IsComplete</th>
                    </tr>
                </thead>
                <tbody>
                    {todo_items.map( todo_item =>
                        <tr key={todo_item.id}>
                            <td>{todo_item.id}</td>
                            <td>{todo_item.name}</td>
                            <td>{String(todo_item.isComplete)}</td>
                        </tr>
                    )}
                </tbody>
            </table>
        );
    }

    render() {
        let forecasts_contents = this.state.loading_forecasts
            ? <p><em>Loading... Please refresh once the ASP.NET backend has started. See <a href="https://aka.ms/jspsintegrationreact">https://aka.ms/jspsintegrationreact</a> for more details.</em></p>
            : App.renderForecastsTable( this.state.forecasts );

        let todo_items_contents = this.state.loading_todo_items
            ? <p><em>Loading... Please refresh once the ASP.NET backend has started. See <a href="https://aka.ms/jspsintegrationreact">https://aka.ms/jspsintegrationreact</a> for more details.</em></p>
            : App.renderTodoItemsTable( this.state.todo_items );

        return (
            <>
                <div>
                    <h1 id="headerLabel" >Swap Sweepstake UI</h1>
                    <p>This component demonstrates fetching data from the server.</p>
                    <p>Environment: {process.env.NODE_ENV}</p>
                    <p>Server URL: {process.env.REACT_APP_SERVER_URL}</p>
                </div>
                <div>
                    <h1 id="forecastTabelLabel" >Weather forecast</h1>
                    {forecasts_contents}
                </div>
                <div>
                    <h1 id="todoItemsTabelLabel" >Todo Items</h1>
                    {todo_items_contents}
                </div>
            </>
        );
    }

    async populateWeatherData() {
        const base_url = process.env.REACT_APP_SERVER_URL;
        const weather_forecast_url = base_url + "/weatherforecast";
        const response = await fetch(weather_forecast_url);
        const data = await response.json();
        let todo_items = this.state.todo_items;
        let loading_todo_items = this.state.loading_todo_items;
        this.setState( { forecasts: data, todo_items: todo_items, loading_forecasts: false, loading_todo_items: loading_todo_items });
    }

    async populateTodoData()
    {
        const base_url = process.env.REACT_APP_SERVER_URL;
        const todo_items_url = base_url + "/api/todoitems";
        const response = await fetch( todo_items_url );
        const data = await response.json();
        let forecasts = this.state.forecasts;
        let loading_forecasts = this.state.loading_forecasts;
        this.setState( { forecasts: forecasts, todo_items: data, loading_forecasts: loading_forecasts, loading_todo_items: false } );
    }
}
